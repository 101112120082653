
/**
 * Define a shared styles in here.
 * This is for preparing styles that are global and unchanged by theme, or only require property value changes based on theme values.
 * ie. Consistent style classes where only the colours change based on theme fed in.
 */

import { makeStyles } from "@material-ui/core";


////////////


export const useSharedStyles = makeStyles( theme => ({

  // Use props passed in like this.
  // className: props => ({
  //   //
  // }),

  pageContentContainer: {
    backgroundColor: theme.palette.body.main,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  pageContent: {
    flexBasis: '1024px',
    maxWidth: '1024px', // maxWidth is also needed or Swiper component's "width: 100%"" pushes the container beyond the flexBasis
    flexGrow: 0,
    flexShrink: 1,
  },

  proportionalPaper: {
    // borderRadius: '35%',// !important',
    width: '9em',
    height: '9em',
    padding: '0.5em',
    textAlign: 'center',
    position: 'relative',
    '& *': {
      position: 'absolute',
      top: '50%',
      left: 0,
      width: '100%',
      transform: 'translateY(-50%)',
      margin: 0,
      padding: 0,
      color: 'inherit',
    },
  },

}));