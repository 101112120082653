import PropTypes from 'prop-types';
import React from 'react';
import { PATHS } from '/src/globals';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import { Link } from '/src/components/Link';
import PageWrapper from '../components/PageWrapper';
import DemoNavigation from '../collections/DemoNavigation';
import { METADATA } from '../globals';

/////////////////////////////////

// NOTE: This page may note show during Gatsby development. Instead, Gatsby will show an inbuilt page and allow clicking through to test this page.
// When deployed this page will be visible immediately.

/////////////////////////////////

const propTypes = {
  theme: PropTypes.object,
};

const NotFound = (props) => {

  return ( <>

    <Helmet>
      <title>Page Not Found{METADATA.titleBarPostfix}</title>
      <meta name="Page Not Found" content="Page not found content description" />
    </Helmet>


    <PageWrapper>

      <Typography variant="h1" align="center">
        That page isn't found.
      </Typography>
      
      <Typography variant="body1" align="center">
        Sorry, but the page you're looking for doesn't exist.<br/>
        Back <Link to={PATHS.root}>to the homepage</Link> you go.
      </Typography>


      <DemoNavigation/>

    </PageWrapper>
    
  </> )

}

NotFound.propTypes = propTypes;
export default NotFound;
