/*
 * PageWrapper
 *
 * This wrapps the whole content area of the page so that it is aligned and sized correctly.
 * There should only be one of these on the page.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSharedStyles } from '../../styles/shared-styles';

//////////////////////////

// const useStyles = makeStyles({
//   adaptiveButton: {
//     width: "auto",
//   },
// });

//////////////////////////

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export const PageWrapper = props => {

  const sharedStyles = useSharedStyles(props);
  // const classes = useStyles({});
  const {
    className,
    children,
  } = props; 

  //////

  return (

    <div className={clsx(
        sharedStyles.pageContentContainer,
        className,
      )}
      >
      <div className={clsx(
          sharedStyles.pageContent,
        )}
        >
        {children}
      </div>
    </div>

  );

};

PageWrapper.propTypes = propTypes;
export default PageWrapper;
